import * as React from 'react';
import ContractLogo from '../icons/contract.svg';

type TService = {
  readonly title: string;
  readonly description?: string;
  readonly icon: React.ReactNode;
};

export const services: TService[] = [
  {
    title: `Arbeits- und Sozialrecht`,
    description: `gerichtliche Vertretung, Erstellung von Dienstverträgen und sonstiger arbeitsrechtlicher Vereinbarungen`,
    icon: <ContractLogo />,
  },
  {
    title: `Schadenersatz- und Gewährleistungsrecht`,
    description: `insbesondere Ärztehaftungen, Beraterhaftungen, Verkehrsunfälle`,
    icon: <ContractLogo />,
  },
  {
    title: `Unternehmensrecht & Gesellschaftsrecht`,
    description: `Gesellschaftsgründungen, Firmenbuchangelegenheiten`,
    icon: <ContractLogo />,
  },
  {
    title: `Ehe- und Familienrecht, Erbrecht`,
    description: `Vertretung in Scheidungs-, Unterhalts- und Obsorgeverfahren, Erstellung von Eheverträgen, Vertretung in Verlassenschaftsverfahren, Erstellung von Testamenten, Vorsorgevollmachten und Patientenverfügungen, Erwachsenenvertretung`,
    icon: <ContractLogo />,
  },
  {
    title: `Firmenvertretungen in sämtlichen Rechtsbereichen`,
    description: `insbesondere Schadenersatz- und Gewährleistungsrecht, Produkthaftung, Datenschutzrecht, Arbeitsrecht, Gewerberecht, Betriebsanlagenrecht, Forderungsbetreibung und Inkasso, Marken- und Musterschutzrecht`,
    icon: <ContractLogo />,
  },
  {
    title: `Liegenschafts- und Immobilienrecht`,
    description: `insbesondere Erstellung von Kauf- und Schenkungsverträgen und deren grundbücherliche und steuerliche Abwicklung, Erstellung von Miet- und Wohnungseigentumsverträgen, gerichtliche Vertretung (Mietzins- und Räumungsklagen)`,
    icon: <ContractLogo />,
  },
  {
    title: `Bauträgerrecht und Bauvertragsrecht`,
    description: `insbesondere Vertragserstellung und grundbuchsrechtliche und steuerrechtliche Abwicklung; Vertretung in baurechtlichen Angelegenheit vor Behörden`,
    icon: <ContractLogo />,
  },
  {
    title: `IT-Recht & Datenschutz`,
    icon: <ContractLogo />,
    description: `insbesondere Erstellung von IT-Verträgen, Verschwiegenheitsvereinbarungen und Datenschutzerklärungen, Vertretung vor der Datenschutzbehörde`,
  },
  {
    title: `Verwaltungs- und Verwaltungsstrafrecht`,
    icon: <ContractLogo />,
    description: `insbesondere Vertretung in Baurecht, Gewerbe- und Betriebsanlagenrecht, Umweltverträglichkeitsprüfungen, Kraftfahrzeug- und Führerscheinrecht`,
  },
];
