import * as React from 'react';
import { Container } from './container';

import './rich_text.scss';

export type TRichTextProps = {
  readonly className?: string;
  readonly children: React.ReactNode;
};

export const RichText: React.FunctionComponent<TRichTextProps> = ({
  children,
  className,
}: TRichTextProps) => {
  const classNames = ['rich_text'];
  if (className) {
    classNames.push(className);
  }

  return (
    <section className={classNames.join(' ')}>
      <Container>{children}</Container>
    </section>
  );
};
