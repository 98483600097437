import * as React from 'react';
import { useLocation } from '@reach/router';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { Footer } from './footer';
import { Header } from './header';
import { services } from '../data/services';

import Logo from '../static/sollhart.png';

import './page.scss';

export type TPageProps = {
  readonly title?: string;
  readonly seoDescription?: string;
  readonly children: React.ReactNode;
  readonly preloadMaps?: boolean;
};

type TSeoProps = {
  readonly defaultTitle: string;
  readonly titleTemplate: string;
  readonly defaultDescription: string;
  readonly siteUrl: string;
  readonly image: string;
};

export const Page: React.FunctionComponent<TPageProps> = ({
  children,
  title,
  seoDescription,
  preloadMaps = false,
}: TPageProps) => {
  const { site } = useStaticQuery<{ site: { siteMetadata: TSeoProps } }>(graphql`
    query MetaDataQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          siteUrl
          image
          defaultDescription: description
        }
      }
    }
  `);
  const { pathname } = useLocation();
  const { siteUrl, image, defaultTitle, defaultDescription, titleTemplate } = site.siteMetadata;

  const url = `${siteUrl}${pathname}`;
  const imageUrl = `${siteUrl}${image}`;
  const seoTitle = title ? titleTemplate.replace('%s', title) : defaultTitle;

  const breadcrumbs = [
    {
      '@type': 'ListItem',
      position: 1,
      name: defaultTitle,
      item: siteUrl,
    },
  ];

  if (title) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: 2,
      name: title,
      item: url,
    });
  }

  const structuredData = [
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      url: url,
      name: defaultTitle,
      logo: `${siteUrl}${Logo}`,
      telephone: '+43 316 832458',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Graz, Austria',
        postalCode: 'A-8010',
        streetAddress: 'Annenstraße 25 / III / 9-10',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 47.07103990864156,
        longitude: 15.427547757144065,
      },
      hasMap:
        'https://www.google.at/maps/dir//Annenstra%C3%9Fe+25,+8020+Graz/@47.0706251,15.4251815,16.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476e35765c07e0e1:0x4cb89395277766d9!2m2!1d15.4275645!2d47.0709828!3e3',
      slogan: 'Ihr Ansprechpartner zum Thema Recht im Herzen von Graz.',
      image: imageUrl,
      openingHours: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '09:00',
          closes: '12:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday'],
          opens: '13:00',
          closes: '16:00',
        },
      ],
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+43 316 832458',
        faxNumber: '+43 316 832458-4',
        email: 'recht@sollhart.at',
        name: 'Kontaktmöglichkeiten',
        contactType: 'Kanzlei',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs,
    },
  ];

  return (
    <>
      <Helmet title={title} defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
        <html lang="de" />
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription || defaultDescription} />
        <meta name="image" content={imageUrl} />
        <meta
          name="keywords"
          content={`Rechtsanwalt Graz Sollhart Anwalt Treuhänder ${services
            .map((service) => service.title)
            .join(' ')}`}
        />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={seoDescription || defaultDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content="789" />
        <meta property="og:image:height" content="598" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={seoDescription || defaultDescription} />
        <meta property="twitter:card" content={imageUrl} />
        <meta
          name="google-site-verification"
          content="ig7BydbgY02YI76l52Pva8dvm13qzVsrDhdo-uuj2qM"
        />
        <meta name="application-name" content={defaultTitle} />
        {preloadMaps && <link rel="preconnect" href="https://maps.googleapis.com" />}
        {preloadMaps && <link rel="preconnect" href="https://fonts.googleapis.com" />}
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <Header />
      <main className="page_wrapper">{children}</main>
      <Footer />
    </>
  );
};
