import * as React from 'react';
import Menu from '../icons/menu.svg';

import './menu_icon.scss';

type TMenuIconProps = {
  readonly isMenuOpen: boolean;
  readonly className?: string;
  readonly onToggleMenu: (isOpen: boolean) => void;
};

export const MenuIcon: React.FunctionComponent<TMenuIconProps> = ({
  isMenuOpen,
  className,
  onToggleMenu,
}: TMenuIconProps) => {
  const toggleMenu = () => {
    onToggleMenu(!isMenuOpen);
  };

  const classNames = ['menu'];
  if (isMenuOpen) {
    classNames.push('opened');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <button className={classNames.join(' ')} onClick={toggleMenu} aria-label="Hauptmenü">
      <Menu />
    </button>
  );
};
