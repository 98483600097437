import { Link } from 'gatsby';
import * as React from 'react';
import { Container } from './container';
import { MenuIcon } from './menu_icon';
import Logo from '../icons/logo.svg';

import './header.scss';

type TMenuEntry = {
  readonly href: string;
  readonly text: string;
};

const menuEntries: TMenuEntry[] = [
  { href: '/ueber-mich', text: 'Über mich' },
  { href: '/fachgebiete', text: 'Fachgebiete' },
  { href: '/kontakt', text: 'Kontakt' },
];

export const Header: React.FunctionComponent = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const classNames = ['page_header'];
  const backdropClassNames = ['mobile_menu_backdrop'];

  if (isMobileMenuOpen) {
    classNames.push('mobile_opened');
    backdropClassNames.push('mobile_opened');
  }

  return (
    <>
      <div className={backdropClassNames.join(' ')} onClick={() => setMobileMenuOpen(false)} />
      <header className={classNames.join(' ')}>
        <Container className="header_wrapper">
          <Link to="/" className="logo" aria-label="Dr. Mario Sollhart | Rechtsanwalt">
            <Logo />
          </Link>
          <MenuIcon
            className="mobile_menu_icon"
            isMenuOpen={isMobileMenuOpen}
            onToggleMenu={setMobileMenuOpen}
          />
          <nav>
            <ul>
              {menuEntries.map((menuEntry, index) => {
                return (
                  <li key={index}>
                    <Link activeClassName="active" to={menuEntry.href} partiallyActive={true}>
                      {menuEntry.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </Container>
        <nav className="mobile_menu">
          <ul>
            {menuEntries.map((menuEntry, index) => {
              return (
                <li key={index}>
                  <Link activeClassName="active" to={menuEntry.href} partiallyActive={true}>
                    {menuEntry.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </header>
    </>
  );
};
