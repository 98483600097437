import * as React from 'react';
import './container.scss';

type TContainerProps = {
  readonly children: React.ReactNode;
  readonly className?: string;
};

export const Container: React.FunctionComponent<TContainerProps> = ({
  children,
  className,
}: TContainerProps) => {
  const classNames = ['container'];
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};
