import { Link } from 'gatsby';
import * as React from 'react';
import EmailIcon from '../icons/email.svg';
import LocationIcon from '../icons/location.svg';
import TelephoneIcon from '../icons/telephone.svg';
import { Container } from './container';

import Logo from '../icons/logo.svg';

import './footer.scss';

export const Footer: React.FunctionComponent = () => {
  return (
    <footer className="page_footer">
      <Container>
        <Logo className="logo" />
        <div className="content_wrapper">
          <div className="footer_section">
            <p>
              <b>Kanzleisitz</b>
              <br />
              Annenstraße 25 / III / 9-10
              <br />
              8020 Graz
              <br />
              <br />
              <b>Besprechungsbüro Südoststeiermark</b>
              <br />
              Patzen 50
              <br />
              8355 Tieschen
              <br />
              (Termin nach Vereinbarung)
            </p>
          </div>
          <div className="footer_section">
            <b>Öffnungszeiten</b>
            <br />
            Montag, Dienstag und Donnerstag
            <br />
            08:00 bis 12:00
            <br />
            14:00 bis 16:00
            <br />
            <br />
            Mittwoch und Freitag
            <br />
            08:00 bis 12:00
          </div>
          <div className="footer_section">
            <nav>
              <ul>
                <li>
                  <Link to="/ueber-mich">Über mich</Link>
                </li>
                <li>
                  <Link to="/fachgebiete">Fachgebiete</Link>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
                <li>
                  <Link to="/datenschutz">Datenschutz</Link>
                </li>
                <li>
                  <Link to="/impressum">Impressum</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer_section">
            <a href="mailto:recht@sollhart.at" className="cta">
              <EmailIcon />
              recht@sollhart.at
            </a>
            <a href="tel:0043316832458" className="cta">
              <TelephoneIcon />
              +43 316 832458
            </a>
            <a
              href="https://www.google.at/maps/dir//Annenstra%C3%9Fe+25,+8020+Graz/@47.0706251,15.4251815,16.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476e35765c07e0e1:0x4cb89395277766d9!2m2!1d15.4275645!2d47.0709828!3e3"
              rel="noreferrer"
              target="_blank"
              className="cta"
            >
              <LocationIcon />
              Anfahrt
            </a>
          </div>
        </div>
      </Container>
      <Container className="copyright">
        <span>© {new Date().getFullYear()} - Dr. Mario Sollhart</span>
      </Container>
    </footer>
  );
};
